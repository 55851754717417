import { useState } from "react";
import { useWindowSize } from "../../utils/useWindowSize";
import classes from "./Missions.module.scss";

const content = [
  {
    title: "OUR VISION",
    text: "Seaside Help envisions its actions as creation of sustainable socio-economic systems that are aimed to address and solve the trouble some issues around the world. These systems may take the form of local non- or for-profit organizations that are built together with local communities. As the system is set - Seaside Help simply will exit from operations and management of such organization, leaving it in the hands of communities. This way local communities will get an ability to be self-sufficient and solve their issues with water, housing, food or pollution in the long term."
  },
  {
    title: "OUR VALUES",
    text: "We envision the world as a united place with no boundaries and we strongly believe in the equality of opportunities for all. We aim to promote and portray this vision of the united world. Our efforts are put to systematically solve specific regional issues via establishment of self-sustainable systems that will be run by local communities. Each specific case requires a specific solution, therefore we will develop a specific plan of actions for each case for a specific budget."
  }
];

const Missions = () => {
  const { width } = useWindowSize();

  const [activeItemIdx, setActiveItemIdx] = useState<number>(0);
  const activeItem = content[activeItemIdx];
  const previewText = activeItem.text;

  if (width === undefined) {
    return null;
  }

  if (width < 800) {
    const preview = (
      <div key={activeItemIdx} className={classes.preview}>
        <div>
          <p>{previewText}</p>
        </div>
      </div>
    );
    return (
      <div id="our-mission" className={classes["missions-container"]}>
        <div className={classes.tabs}>
          {content.map((item, idx) => {
            const first = item.title.split(" ")[0];
            const second = item.title.split(" ")[1];

            return (
              <>
                <div
                  key={Math.random().toString().slice(2, 7)}
                  onClick={() => setActiveItemIdx(idx)}
                  className={
                    idx === activeItemIdx ? classes["active-tab"] : classes["inactive-tab"]
                  }>
                  <h3>{first}</h3>
                  <h3>{second}</h3>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                {idx === activeItemIdx && preview}
              </>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div id="our-mission" className={classes["missions-container"]}>
      {content.map((item) => (
        <div key={Math.random().toString().slice(2, 7)} className={classes.preview}>
          <div>
            <h2>
              {item.title.split(" ").map((text) => (
                <span key={text}>{text}</span>
              ))}
            </h2>
            <p>{item.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Missions;
