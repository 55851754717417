import Container from "../../ui/Container";
import Circles from "./Circles";
import Missions from "./Missions";
import Youtube from "./Youtube";

import classes from "./AboutSection.module.scss";
import Founder from "./Founder";

const AboutSection = () => {
  return (
    <section id="about-foundation" className={classes.section}>
      <Container>
        <h2 className={classes["section-header"]}>
          We are taking on this mission because there are:
        </h2>
        <Circles />
        <Founder />
        <Missions />
      </Container>
      <Youtube />
    </section>
  );
};

export default AboutSection;
