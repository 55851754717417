import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AboutFoundation from "./pages/AboutFoundation";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import NotFound from "./pages/NotFound";
import Cursor from "./ui/Cursor/Cursor";
import Cookies from "./components/Cookies";
import { useWindowSize } from "./utils/useWindowSize";

function App() {
  const windowSize = useWindowSize();
  const isMobileView = windowSize.width ? windowSize.width < 800 : false;
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    scrollToTop();
    window.addEventListener("load", scrollToTop);
    return () => {
      window.removeEventListener("load", scrollToTop);
    };
  }, []);
  return (
    <div className="App">
      {isMobileView ? null : <Cursor />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutFoundation />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Cookies />
    </div>
  );
}

export default App;
