import { useMemo } from "react";

import { getSocialLinks } from "../../data/social-links";

type Props = {
  iconType: "primary" | "secondary";
  className?: string;
  size: number;
};

const SocialIcons = ({ iconType, size, className }: Props) => {
  const socialLinks = useMemo(() => getSocialLinks(iconType, size), [iconType]);
  return (
    <div className={className}>
      {socialLinks.map((item) => {
        if (item.url) {
          return (
            <a key={item.name} href={item.url} target="_blank" rel="noreferrer">
              {item.icon}
            </a>
          );
        }
      })}
    </div>
  );
};

export default SocialIcons;
