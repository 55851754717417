import React from "react";
import classes from "./IconStyles.module.scss";

type Props = {
  type: "primary" | "secondary";
  size?: number;
};

const Telegram: React.FC<Props> = ({ type, size }) => {
  let color: string;

  if (type === "secondary") {
    color = "#EB9309";
  } else {
    color = "#333333";
  }

  return (
    <svg
      className={classes[type]}
      width={size || 15}
      height={size || 15}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_771_463)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.11926 0.2388C4.1626 0.2388 0.955078 3.44632 0.955078 7.40298C0.955078 11.3596 4.1626 14.5672 8.11926 14.5672C12.0759 14.5672 15.2834 11.3596 15.2834 7.40298C15.2834 3.44632 12.0759 0.2388 8.11926 0.2388ZM11.1048 4.94008C11.4336 5.03032 11.6926 5.29617 11.7804 5.63378C11.9402 6.24567 11.9402 7.52238 11.9402 7.52238C11.9402 7.52238 11.9402 8.79904 11.7804 9.41098C11.6926 9.74859 11.4336 10.0144 11.1048 10.1047C10.5089 10.2686 8.11926 10.2686 8.11926 10.2686C8.11926 10.2686 5.72958 10.2686 5.13367 10.1047C4.80484 10.0144 4.54588 9.74859 4.458 9.41098C4.29836 8.79904 4.29836 7.52238 4.29836 7.52238C4.29836 7.52238 4.29836 6.24567 4.458 5.63378C4.54588 5.29617 4.80484 5.03032 5.13367 4.94008C5.72958 4.77611 8.11926 4.77611 8.11926 4.77611C8.11926 4.77611 10.5089 4.77611 11.1048 4.94008Z"
          fill={color}
        />
        <path d="M7.40283 8.83587V6.44781L9.31328 7.64187L7.40283 8.83587Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_771_463">
          <rect
            width="14.3284"
            height="14.3284"
            fill="white"
            transform="translate(0.955078 0.2388)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Telegram;
