import { Instagram, Facebook, Telegram, Youtube, Twitter, Linkedin } from "../assets/icons";

export const getSocialLinks = (type: "primary" | "secondary", size: number) => [
  {
    name: "Instagram",
    url: "https://instagram.com/seasideclubofficial",
    icon: <Instagram type={type} size={size} />
  },
  {
    name: "Facebook",
    url: "https://facebook.com/seasideclubofficial/",
    icon: <Facebook type={type} size={size} />
  },
  {
    name: "Youtube",
    url: "https://www.youtube.com/channel/UCQTAtMZ-Lk79KmIB-DvqA-w",
    icon: <Youtube type={type} size={size} />
  },
  {
    name: "Twitter",
    url: "https://twitter.com/Seaside_investm",
    icon: <Twitter type={type} size={size} />
  },
  { name: "Telegram", url: "https://t.me/seasideclub", icon: <Telegram type={type} size={size} /> },
  {
    name: "Linkedin",
    url: "https://www.linkedin.com/company/seaside-club-official",
    icon: <Linkedin type={type} size={size} />
  }
];
