import { useEffect, useRef } from "react";
import classes from "./Cursor.module.scss";

const Cursor = () => {
  const cursorDivRef = useRef<HTMLDivElement>(null);
  const pointerDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const cursorDiv = cursorDivRef.current;
    const pointerDiv = pointerDivRef.current;
    if (!cursorDiv || !pointerDiv) return;
    const onMouseLeave = () => {
      cursorDiv.style.opacity = "0";
      pointerDiv.style.opacity = "0";
    };

    const onMouseEnter = () => {
      cursorDiv.style.opacity = "1";
      pointerDiv.style.opacity = "1";
    };

    const onMouseMove = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.dataset?.cursor === "clickable") {
        cursorDiv.classList.add(classes.zoom);
      } else {
        cursorDiv.classList.remove(classes.zoom);
      }

      cursorDiv.style.left = e.pageX + "px";
      cursorDiv.style.top = e.pageY + "px";
      pointerDiv.style.left = e.pageX + "px";
      pointerDiv.style.top = e.pageY + "px";
    };

    let timeout: ReturnType<typeof setTimeout>;

    const onClick = () => {
      cursorDiv.classList.add(classes.animated);
      timeout = setTimeout(() => {
        cursorDiv.classList.remove(classes.animated);
      }, 700);
    };

    document.body.addEventListener("mouseleave", onMouseLeave);
    document.body.addEventListener("mouseenter", onMouseEnter);
    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("click", onClick);

    return () => {
      document.body.removeEventListener("mouseleave", onMouseLeave);
      document.body.removeEventListener("mouseenter", onMouseEnter);
      document.body.removeEventListener("mousemove", onMouseMove);
      document.body.removeEventListener("click", onClick);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <div ref={cursorDivRef} className={classes.cursor}></div>
      <div ref={pointerDivRef} className={classes.pointer}></div>
    </>
  );
};

export default Cursor;
