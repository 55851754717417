import { useEffect, useState } from "react";
import classes from "./Cookies.module.scss";

const Cookies = () => {
  const [showDialog, setShowDialog] = useState(true);
  const ls = window.localStorage;
  useEffect(() => {
    const cookieDisclaimer = ls.getItem("cookieDisclaimer");
    if (cookieDisclaimer === "true") setShowDialog(false);
  }, []);
  const acceptDisclaimer = () => {
    ls.setItem("cookieDisclaimer", "true");
    setShowDialog(false);
  };

  if (showDialog) {
    return (
      <div className={classes.container}>
        <p>
          This website uses cookies to improve user experience. By clicking agree you consent to all
          cookies in accordance with our Cookie Policy.
        </p>
        <button onClick={acceptDisclaimer}>AGREE</button>
      </div>
    );
  } else {
    return null;
  }
};

export default Cookies;
