import React from "react";
import classes from "./IconStyles.module.scss";

type Props = {
  type: "primary" | "secondary";
  size?: number;
};

const Instagram: React.FC<Props> = ({ type, size }) => {
  let color: string;

  if (type === "secondary") {
    color = "#EB9309";
  } else {
    color = "#333333";
  }

  return (
    <svg
      className={classes[type]}
      width={size || 15}
      height={size || 15}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_771_455)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.95519 0.2388C3.99854 0.2388 0.791016 3.44632 0.791016 7.40298C0.791016 11.3596 3.99854 14.5672 7.95519 14.5672C11.9118 14.5672 15.1194 11.3596 15.1194 7.40298C15.1194 3.44632 11.9118 0.2388 7.95519 0.2388ZM6.38006 3.60516C6.78761 3.58662 6.91785 3.58208 7.95555 3.58208H7.95436C8.99239 3.58208 9.12212 3.58662 9.5297 3.60516C9.93648 3.62378 10.2143 3.6882 10.4579 3.78268C10.7094 3.8802 10.9219 4.01074 11.1345 4.22328C11.347 4.43567 11.4776 4.64883 11.5755 4.90014C11.6694 5.1431 11.7339 5.42074 11.753 5.82752C11.7713 6.2351 11.7761 6.36531 11.7761 7.40301C11.7761 8.44071 11.7713 8.57062 11.753 8.9782C11.7339 9.3848 11.6694 9.66253 11.5755 9.90557C11.4776 10.1568 11.347 10.37 11.1345 10.5824C10.9222 10.7949 10.7093 10.9258 10.4581 11.0234C10.215 11.1178 9.93701 11.1822 9.53027 11.2009C9.12269 11.2194 8.99286 11.224 7.95507 11.224C6.91746 11.224 6.78731 11.2194 6.37973 11.2009C5.97304 11.1822 5.69531 11.1178 5.45221 11.0234C5.20105 10.9258 4.98788 10.7949 4.77558 10.5824C4.5631 10.37 4.43257 10.1568 4.3349 9.90549C4.24048 9.66253 4.17609 9.38489 4.15737 8.97811C4.13893 8.57056 4.1343 8.44071 4.1343 7.40301C4.1343 6.36531 4.13907 6.23501 4.15731 5.82743C4.17561 5.42083 4.24009 5.1431 4.33481 4.90005C4.43272 4.64883 4.56328 4.43567 4.77582 4.22328C4.98821 4.01083 5.20137 3.88026 5.45269 3.78268C5.69564 3.6882 5.97328 3.62378 6.38006 3.60516Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.61285 4.27073C7.67939 4.27064 7.751 4.27067 7.82826 4.2707L7.95563 4.27073C8.97581 4.27073 9.09673 4.2744 9.4996 4.2927C9.87214 4.30975 10.0743 4.37198 10.209 4.42428C10.3873 4.49354 10.5144 4.57634 10.6481 4.71007C10.7818 4.84381 10.8646 4.97118 10.934 5.14948C10.9863 5.28401 11.0487 5.48619 11.0656 5.85873C11.0839 6.26154 11.0879 6.38252 11.0879 7.40222C11.0879 8.42195 11.0839 8.54294 11.0656 8.94574C11.0486 9.31828 10.9863 9.52046 10.934 9.655C10.8648 9.8333 10.7818 9.96028 10.6481 10.0939C10.5144 10.2277 10.3874 10.3105 10.209 10.3797C10.0745 10.4323 9.87214 10.4943 9.4996 10.5114C9.09679 10.5297 8.97581 10.5337 7.95563 10.5337C6.93536 10.5337 6.81444 10.5297 6.41163 10.5114C6.03909 10.4942 5.83691 10.4319 5.70214 10.3796C5.52384 10.3104 5.39647 10.2276 5.26273 10.0938C5.129 9.9601 5.04623 9.83306 4.97679 9.65467C4.9245 9.52016 4.86217 9.31795 4.84521 8.94542C4.82691 8.54264 4.82324 8.42163 4.82324 7.40127C4.82324 6.38094 4.82691 6.26058 4.84521 5.85777C4.86226 5.48524 4.9245 5.28306 4.97679 5.14837C5.04605 4.97004 5.129 4.8427 5.26273 4.70897C5.39647 4.57524 5.52384 4.49243 5.70214 4.42303C5.83682 4.37049 6.03909 4.3084 6.41163 4.29127C6.76411 4.27536 6.90073 4.27058 7.61285 4.26978V4.27073ZM9.99521 4.90518C9.74208 4.90518 9.53667 5.11031 9.53667 5.36354C9.53667 5.61667 9.74208 5.82204 9.99521 5.82204C10.2483 5.82204 10.4537 5.61667 10.4537 5.36354C10.4537 5.1104 10.2483 4.90503 9.99521 4.90503V4.90518ZM5.99342 7.40312C5.99342 6.31951 6.87193 5.44094 7.95554 5.44091C9.03918 5.44091 9.91751 6.31948 9.91751 7.40312C9.91751 8.48676 9.03924 9.36494 7.95563 9.36494C6.87199 9.36494 5.99342 8.48676 5.99342 7.40312Z"
          fill={color}
        />
        <path
          d="M7.95553 6.12949C8.6589 6.12949 9.22917 6.69969 9.22917 7.40313C9.22917 8.1065 8.6589 8.67677 7.95553 8.67677C7.25209 8.67677 6.68188 8.1065 6.68188 7.40313C6.68188 6.69969 7.25209 6.12949 7.95553 6.12949Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_771_455">
          <rect
            width="14.3284"
            height="14.3284"
            fill="white"
            transform="translate(0.791016 0.2388)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Instagram;
