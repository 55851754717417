import { hussein } from "../../assets/images";
import classes from "./Founder.module.scss";

const Founder = () => {
  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <h2>Hussein Karaki</h2>
        <h4>Vision from the founder</h4>
        <span />
        <p>
          I live by the principle of developing myself as a human being. I always work to be better
          than I was the day or the month or the year before. With Seaside Help I want to bring a
          difference to selfish and egocentric global culture. People around the globe are still
          experiencing famine, lack of water supply, lack of education, lack of shelters and
          infrastructure - even in the 2020s we are facing the same troubles as decades ago. At the
          same time modern culture is pushing people to get more separated than ever making it even
          more hard to unite and answer the challenges.
        </p>
        <p>
          By building long-lasting and self-sustainable socio-economic systems we aim to
          systematically address these issues via direct cooperation with local communities and
          without any external funding: all operations of Seaside Help are funded from the revenues
          generated by Seaside Club group of companies. Our team is formed of people with diverse
          backgrounds, different beliefs and religions. We are united to lead by example and promote
          the idea of unification and inclusivity - since only when we are united we can actually
          make a difference.
        </p>
      </div>
      <div className={classes.image}>
        <img src={hussein} alt="HUSSEIN KARAKI" />
      </div>
    </div>
  );
};

export default Founder;
