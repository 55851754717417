import { HashLink } from "react-router-hash-link";
import classes from "./NavigationDesktop.module.scss";
import { navLinks } from "../../data/navigation";

const NavigationDesktop = () => {
  return (
    <nav className={classes["navigation-desktop"]}>
      <ul>
        {navLinks.map((item) => (
          <li key={item.id}>
            <HashLink
              data-cursor="clickable"
              to={item.url}
              scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}>
              {item.pageName}
            </HashLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavigationDesktop;
