import Container from "../../ui/Container";
import Button from "../../ui/Button";
import { appLogoImage } from "../../assets/images";

import classes from "./SubmitOfferSection.module.scss";

const SubmitOfferSection = () => {
  return (
    <section className={classes.section}>
      <Container>
        <div className={classes.content}>
          <div className={classes["logo-container"]}>
            <img src={appLogoImage} alt="logo" />
            <span>SEASIDE HELP</span>
          </div>
          <div className={classes.submit}>
            <h2>HOW MAY WE HELP?</h2>
            <p>
              Share with us the vision of a problem you would like to get assistance with. We will
              review your case and will contact you back.
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSf5_xA4ocaB0oDptXZLiZco6oTwLJ2ueW73P3JqL9xE008FLg/viewform"
              target="_blank"
              rel="noreferrer">
              <Button>FILL A FORM</Button>
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SubmitOfferSection;
