import React from "react";
import { Link } from "react-router-dom";
import Container from "../../ui/Container";
import classes from "./Breadcrumbs.module.scss";

type Props = {
  currentPage: string;
  pageHeading: string;
};

const Breadcrumbs: React.FC<Props> = ({ currentPage, pageHeading }) => {
  return (
    <div className={classes.container}>
      <Container>
        <ul>
          <li>
            <Link data-cursor="clickable" to="/">
              Main Page
            </Link>
          </li>
          <li>{currentPage}</li>
        </ul>
        <h1>{pageHeading}</h1>
      </Container>
    </div>
  );
};

export default Breadcrumbs;
