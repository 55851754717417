import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSpring, animated } from "react-spring";

import classes from "./Circle.module.scss";

type Props = {
  amount: number;
  unit: string;
  title: string;
  text: string;
  className: string;
};

const Circle: React.FC<Props> = ({ amount, unit, title, text, className }) => {
  const [value, setValue] = useState(0);
  const springProps = useSpring({ val: value, from: { val: 0 }, config: { duration: 3000 } });
  const styles = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 3000 }
  });

  const circleRef = useRef<HTMLDivElement>(null);

  const handleObserver = useCallback((entries: IntersectionObserverEntry[]) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setValue(amount);
    }
  }, []);

  useEffect(() => {
    const options = { root: null, rootMargin: "20px", threshold: 0 };
    const observer = new IntersectionObserver(handleObserver, options);
    if (circleRef.current) observer.observe(circleRef.current);
  }, [handleObserver]);

  return (
    <div className={`${className} ${classes.circle}`}>
      <animated.div ref={circleRef} className={classes.amount} style={{ ...styles }}>
        <animated.span>
          {springProps.val.to((val) => {
            const precise = val.toFixed(1);
            const toArray = precise.toString().split(".");
            if (toArray[1] === "0") {
              return `${toArray[0]},`;
            } else {
              return `${precise},`;
            }
          })}
        </animated.span>
        <span>{unit}</span>
      </animated.div>
      <h3 className={classes.title}>{title}</h3>
      <p className={classes.text}>{text}</p>
    </div>
  );
};

export default Circle;
