import React from "react";
import classes from "./Button.module.scss";

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  variant?: "contained" | "outlined";
  fullWidth?: boolean;
};

const Button = ({ children, onClick, variant = "contained", fullWidth }: Props) => {
  const classNames = `${classes.button} ${classes[variant]} ${fullWidth && classes["fullWidth"]}`;

  return (
    <button data-cursor="clickable" className={classNames} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
