import { useSpring, animated, easings } from "react-spring";
import { useInView } from "react-intersection-observer";

import { packages, children } from "../../assets/images";
import classes from "./Youtube.module.scss";

const Youtube = () => {
  const { ref, inView } = useInView();

  const img1ContainerStyles = useSpring({
    from: { width: "0%" },
    to: { width: inView ? "100%" : "0%" },
    config: { duration: 2500, delay: 1000, easing: easings.easeOutQuad }
  });

  const img1Styles = useSpring({
    from: { transform: "scale(1.5)" },
    to: { transform: inView ? "scale(1)" : "scale(1.7)" },
    config: { duration: 4000, easing: easings.easeOutQuad }
  });

  return (
    <div ref={ref} className={classes.container}>
      <animated.div style={{ ...img1ContainerStyles }} className={classes["image-container"]}>
        <animated.img style={{ ...img1Styles }} src={packages} alt="packages" />
      </animated.div>
      <animated.div style={{ ...img1ContainerStyles }} className={classes["image-container2"]}>
        <animated.img style={{ ...img1Styles }} src={children} alt="children" />
      </animated.div>

      <span className={classes["youtube-circle"]}>
        <span>
          <h2>TOGETHER</h2>
          <h2>WE MAKE</h2>
          <h2>A DIFFERENCE</h2>
        </span>
      </span>
    </div>
  );
};

export default Youtube;
