export const navLinks = [
  {
    id: "0001",
    pageName: "About us",
    url: "/about-us"
  }
  //   {
  //     id: "0002",
  //     pageName: "About Foundation",
  //     url: "/#about-foundation"
  //   },
  //   {
  //     id: "0003",
  //     pageName: "Our Mission",
  //     url: "/#our-mission"
  //   }
];
