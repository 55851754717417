import React from "react";
import classes from "./IconStyles.module.scss";

type Props = {
  type: "primary" | "secondary";
  size?: number;
};

const Linkedin: React.FC<Props> = ({ type, size }) => {
  let color: string;

  if (type === "secondary") {
    color = "#EB9309";
  } else {
    color = "#333333";
  }

  return (
    <svg
      className={classes[type]}
      width={size || 15}
      height={size || 15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        transform="translate(-2,-2)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.165 2C5.20788 2 2 5.20788 2 9.165C2 13.1221 5.20788 16.33 9.165 16.33C13.1221 16.33 16.33 13.1221 16.33 9.165C16.33 5.20788 13.1221 2 9.165 2ZM12.75 12.417V9.93498C12.75 8.60314 12.0235 7.96749 11.0852 7.96749C10.3285 7.96749 9.96525 8.39126 9.78363 8.69395V8.08857H8.33072C8.36099 8.48206 8.33072 12.417 8.33072 12.417H9.78363V9.99552C9.78363 9.84417 9.8139 9.72309 9.84417 9.63229C9.93498 9.39013 10.1771 9.11771 10.5706 9.11771C11.0852 9.11771 11.2971 9.51121 11.2971 10.0863V12.417H12.75ZM6 6.75673C6 6.33296 6.33296 6 6.81726 6C7.33184 6 7.63453 6.30269 7.60426 6.75673C7.60426 7.18049 7.30157 7.51345 6.787 7.51345C6.30269 7.51345 6 7.18049 6 6.75673ZM7.54372 12.417H6.09081V8.08857H7.54372V12.417Z"
        fill={color}
      />
    </svg>
  );
};

export default Linkedin;
