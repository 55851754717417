import Container from "../../ui/Container";
import contentClasses from "./ContentClasses.module.scss";

export const PrivacyPolicyContent = () => {
  return (
    <Container>
      <div className={`${contentClasses.container}`}>
        <span>Date of Last Revision: May 12, 2022</span>
        <article>
          <h3>Consent</h3>
          <p>
            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
          </p>
        </article>
        <article>
          <h3>Information we collect</h3>
          <p>
            The personal information that you are asked to provide, and the reasons why you are
            asked to provide it, will be made clear to you at the point we ask you to provide your
            personal information.
          </p>
          <p>
            If you contact us directly, we may receive additional information about you such as your
            name, email address, the contents of the message and/or attachments you may send us, and
            any other information you may choose to provide.
          </p>
        </article>
        <article>
          <h3>How we use your information</h3>
          <p>We use the information we collect in various ways, including to:</p>
          <ul>
            <li>
              Service Providers: To assist us in meeting business operations needs and to perform
              certain services and functions, we may share personal information with service
              providers, including hosting services, cloud services, and other information
              technology services, email communication software and email newsletter services,
              advertising and marketing services, payment processors, customer relationship
              management and customer support services, and analytics services. Pursuant to our
              instructions, these parties will access, process, or store personal information in the
              course of performing their duties to us.
            </li>
            <li>
              Professional Advisors: We may share personal information with our professional
              advisors such as lawyers and accountants where doing so is necessary to facilitate the
              services they render to us.
            </li>
            <li>
              Business Transfers: If we are involved in a merger, acquisition, financing,
              reorganization, bankruptcy, receivership, dissolution, sale of all or a portion of our
              assets, or transition of service to another provider (collectively a “Transaction”),
              your personal information may be shared in the diligence process with counterparties
              and others assisting with the Transaction and transferred to a successor or affiliate
              as part of or following that Transaction along with other assets.
            </li>
            <li>
              Legal Requirements: We do not volunteer your personal information to government
              authorities or regulators, but we may disclose your personal information where
              required to do so for the Compliance and Protection purposes described above.
            </li>
          </ul>
          <p>
            Your Sharing: Through the Service, you may be able to make your personal information
            available to others, including:
          </p>
          <ul>
            <li>
              Other users and the public, when you post content through the Discord community
              platform and engage in public transactions through the Service; and
            </li>
            <li>Social media platforms, when you choose to share content on social media.</li>
          </ul>
        </article>
        <article>
          <h3>4. Children</h3>
          <p>
            Our Service is not directed to children who are under the age of 16. Proof does not
            knowingly collect personal information from children under the age of 16. If you believe
            that a child under the age of 16 provided personal information to Proof please contact
            us and we will delete that information.
          </p>
        </article>
        <article>
          <h3>5. LINKS TO OTHER WEBSITES</h3>
          <p>
            The Service may contain links to other websites not operated or controlled by Proof,
            including social media services (“Third Party Sites”). The information that you share
            with Third Party Sites will be governed by the specific privacy policies and terms of
            service of the Third Party Sites and not by this Privacy Policy. By providing these
            links we do not imply that we endorse or have reviewed these sites. Please contact the
            Third Party Sites directly for information on their privacy practices and policies.
          </p>
        </article>
        <article>
          <h3>6. SECURITY</h3>
          <p>
            We employ a number of technical, organizational and physical safeguards designed to
            protect the personal information we collect. However, no security measures are failsafe
            and we cannot guarantee the security of your personal information. You use the Service
            at your own risk.
          </p>
        </article>
        <article>
          <h3>7. PERSONAL INFORMATION PROCESSING IN THE U.S.</h3>
          <p>
            Proof is headquartered in the United States. By using our Service, you understand and
            acknowledge that your personal information will be transferred from your location to our
            facilities and servers in the United States, where data protection laws may be less
            protective than those in your jurisdiction.
          </p>
        </article>
        <article>
          <h3>8. YOUR CHOICES</h3>
          <p>
            Update or Correct Personal Information: You can contact us and request any updates or
            corrections needed to keep your personal information accurate, current, and complete.
          </p>
          <p>
            Opt Out of Marketing Communications: You may opt out of marketing-related communications
            by following the opt out or unsubscribe instructions contained in any marketing
            communication we send you. Please note, however, that you may continue to receive
            communications as described in the Communicating with You section after opting out of
            marketing communications.
          </p>
          <p>
            Do Not Track. We currently do not respond to “Do Not Track” or similar signals. Learn
            more about “Do Not Track” here.
          </p>
        </article>
        <article>
          <h3>9. CHANGES TO THE PRIVACY POLICY</h3>
          <p>
            The Service and our business may change from time to time. As a result we may change
            this Privacy Policy at any time. When we do we will post an updated version on this
            page, unless another type of notice is required by applicable law. By continuing to use
            our Service or providing us with personal information after we have posted an updated
            Privacy Policy, or notified you by other means if applicable, you consent to the revised
            Privacy Policy and the practices described in it.
          </p>
        </article>
        <article>
          <h3>10. CONTACT US</h3>
          <p>
            If you have any questions about our Privacy Policy or information practices, please feel
            free to contact us at our designated request address: support@proof.xyz
          </p>
        </article>
      </div>
    </Container>
  );
};
