import React from "react";
import classes from "./IconStyles.module.scss";

type Props = {
  type: "primary" | "secondary";
  size?: number;
};

const Facebook: React.FC<Props> = ({ type, size }) => {
  let color: string;
  if (type === "secondary") {
    color = "#EB9309";
  } else {
    color = "#333333";
  }
  return (
    <svg
      className={classes[type]}
      width={size || 15}
      height={size || 15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_771_460)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.53722 0.2388C3.58057 0.2388 0.373047 3.44632 0.373047 7.40298C0.373047 11.3596 3.58057 14.5672 7.53722 14.5672C11.4939 14.5672 14.7014 11.3596 14.7014 7.40298C14.7014 3.44632 11.4939 0.2388 7.53722 0.2388ZM8.28397 7.71766V11.6154H6.67128V7.71781H5.86558V6.37465H6.67128V5.56823C6.67128 4.47247 7.12621 3.82089 8.41878 3.82089H9.49487V5.1642H8.82224C8.31908 5.1642 8.28579 5.3519 8.28579 5.70223L8.28397 6.3745H9.50248L9.35988 7.71766H8.28397Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_771_460">
          <rect
            width="14.3284"
            height="14.3284"
            fill="white"
            transform="translate(0.373047 0.2388)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Facebook;
