import React from "react";
import Header from "../../components/Header";
import HeroSection from "../../components/HeroSection/HeroSection";
import SubmitOfferSection from "../../components/SubmitOfferSection/SubmitOfferSection";
import AboutSection from "../../components/AboutSection";
import Footer from "../../components/Footer";

const Home: React.FC = () => {
  return (
    <div>
      <HeroSection>
        <Header isOnHomepage />
      </HeroSection>
      <SubmitOfferSection />
      <AboutSection />
      <Footer />
    </div>
  );
};

export default Home;
