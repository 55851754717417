import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Breadcrumbs from "../../components/Breadcrumbs";
import Footer from "../../components/Footer";
import classes from "./NotFound.module.scss";
import Button from "../../ui/Button";

const notFoundText = "404, page not found.";

const NotFound = () => {
  const [typed404, setTyped404] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(
      () =>
        setTyped404((prevState) => {
          return notFoundText.slice(0, prevState.length + 1);
        }),
      50
    );
    return () => clearTimeout(timeout);
  }, [typed404]);

  return (
    <>
      <Header />
      <Breadcrumbs currentPage="404" pageHeading="Not Found " />
      <section className={classes.section}>
        <div className={classes.container}>
          <span className={classes.text}>{typed404}</span>
          <span className={classes.handle}></span>
        </div>
        <Button onClick={() => navigate("/")}>Go back</Button>
      </section>

      <Footer />
    </>
  );
};

export default NotFound;
