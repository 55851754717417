import React from "react";
import classes from "./IconStyles.module.scss";

type Props = { onClick?: () => void };

const ScrollUp: React.FC<Props> = (props) => {
  const classNames = props.onClick ? classes.clickable : undefined;
  return (
    <svg
      data-cursor="clickable"
      className={classNames}
      onClick={props.onClick}
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17.2102" r="17" fill="#EB9309" />
      <path
        d="M13.5508 14.5007C13.4333 14.6171 13.2746 14.6823 13.1092 14.6823C12.9438 14.6823 12.7851 14.6171 12.6676 14.5007C12.6096 14.4435 12.5635 14.3754 12.532 14.3003C12.5006 14.2252 12.4844 14.1446 12.4844 14.0631C12.4844 13.9817 12.5006 13.9011 12.532 13.8259C12.5635 13.7508 12.6096 13.6827 12.6676 13.6256L16.197 10.1666C16.3148 10.0504 16.4736 9.98523 16.6391 9.98523C16.8045 9.98523 16.9634 10.0504 17.0812 10.1666L20.6106 13.6256C20.6686 13.6827 20.7147 13.7508 20.7461 13.8259C20.7776 13.9011 20.7938 13.9817 20.7938 14.0631C20.7938 14.1446 20.7776 14.2252 20.7461 14.3003C20.7147 14.3754 20.6686 14.4435 20.6106 14.5007C20.4928 14.6169 20.334 14.682 20.1685 14.682C20.003 14.682 19.8442 14.6169 19.7264 14.5007L16.6377 11.664L13.5508 14.5007Z"
        fill="#F9F9F9"
      />
      <path
        d="M13.5508 19.0163C13.4333 19.1327 13.2746 19.198 13.1092 19.198C12.9438 19.198 12.7851 19.1327 12.6676 19.0163C12.6096 18.9592 12.5635 18.8911 12.532 18.8159C12.5006 18.7408 12.4844 18.6602 12.4844 18.5787C12.4844 18.4973 12.5006 18.4167 12.532 18.3416C12.5635 18.2664 12.6096 18.1983 12.6676 18.1412L16.197 14.6822C16.3148 14.566 16.4736 14.5009 16.6391 14.5009C16.8045 14.5009 16.9634 14.566 17.0812 14.6822L20.6106 18.1412C20.6686 18.1983 20.7147 18.2664 20.7461 18.3416C20.7776 18.4167 20.7938 18.4973 20.7938 18.5787C20.7938 18.6602 20.7776 18.7408 20.7461 18.8159C20.7147 18.8911 20.6686 18.9592 20.6106 19.0163C20.4928 19.1325 20.334 19.1977 20.1685 19.1977C20.003 19.1977 19.8442 19.1325 19.7264 19.0163L16.6377 16.1796L13.5508 19.0163Z"
        fill="#F9F9F9"
      />
      <path
        d="M13.5508 23.5319C13.4333 23.6483 13.2746 23.7136 13.1092 23.7136C12.9438 23.7136 12.7851 23.6483 12.6676 23.5319C12.6096 23.4748 12.5635 23.4067 12.532 23.3316C12.5006 23.2564 12.4844 23.1758 12.4844 23.0944C12.4844 23.0129 12.5006 22.9323 12.532 22.8572C12.5635 22.7821 12.6096 22.714 12.6676 22.6568L16.197 19.1978C16.3148 19.0816 16.4736 19.0165 16.6391 19.0165C16.8045 19.0165 16.9634 19.0816 17.0812 19.1978L20.6106 22.6568C20.6686 22.714 20.7147 22.7821 20.7461 22.8572C20.7776 22.9323 20.7938 23.0129 20.7938 23.0944C20.7938 23.1758 20.7776 23.2564 20.7461 23.3316C20.7147 23.4067 20.6686 23.4748 20.6106 23.5319C20.4928 23.6481 20.334 23.7133 20.1685 23.7133C20.003 23.7133 19.8442 23.6481 19.7264 23.5319L16.6377 20.6952L13.5508 23.5319Z"
        fill="#F9F9F9"
      />
    </svg>
  );
};

export default ScrollUp;
