import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "../../ui/Container";
import NavigationMobile from "./NavigationMobile";
import { appLogoImage } from "../../assets/images";
import { useWindowSize } from "../../utils/useWindowSize";

import classes from "./Header.module.scss";
import NavigationDesktop from "./NavigationDesktop";

type Props = {
  isOnHomepage?: boolean;
};

const Header: React.FC<Props> = ({ isOnHomepage }) => {
  const [showNavigation, setShwNavigation] = useState<boolean>(false);
  const windowSize = useWindowSize();
  const isMobileView = windowSize.width ? windowSize.width < 1001 : false;

  const headerClass = isOnHomepage ? `${classes.header} ${classes["on-homepage"]}` : classes.header;
  return (
    <header className={headerClass}>
      <Container>
        <div className={classes["header-container"]}>
          <Link to="/" className={classes["logo-container"]} data-cursor="clickable">
            <img src={appLogoImage} alt="LOGO" data-cursor="clickable" />
            <span className={classes.sitename} data-cursor="clickable">
              SEASIDE HELP
            </span>
          </Link>
          {isMobileView ? (
            <div className={classes["menu-button-container"]}>
              <div
                className={`${classes.burger} ${showNavigation ? classes.toggled : undefined}`}
                onClick={() => setShwNavigation((prevState) => !prevState)}>
                <div></div>
              </div>
              <NavigationMobile isOpen={showNavigation} onClose={() => setShwNavigation(false)} />
            </div>
          ) : (
            <NavigationDesktop />
          )}
        </div>
      </Container>
    </header>
  );
};

export default Header;
