import React from "react";
import classes from "./IconStyles.module.scss";

type Props = {
  type: "primary" | "secondary";
  size?: number;
};

const Telegram: React.FC<Props> = ({ type, size }) => {
  let color: string;

  if (type === "secondary") {
    color = "#EB9309";
  } else {
    color = "#333333";
  }

  return (
    <svg
      className={classes[type]}
      width={size || 15}
      height={size || 15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_771_467)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.70153 0.2388C3.74488 0.2388 0.537354 3.44632 0.537354 7.40298C0.537354 11.3596 3.74488 14.5672 7.70153 14.5672C11.6582 14.5672 14.8657 11.3596 14.8657 7.40298C14.8657 3.44632 11.6582 0.2388 7.70153 0.2388ZM7.50201 6.30984L7.48696 6.06193C7.44186 5.41943 7.83774 4.83256 8.46416 4.60492C8.69467 4.52396 9.08553 4.51384 9.34111 4.58468C9.44132 4.61504 9.63177 4.7162 9.76705 4.80728L10.0126 4.97423L10.2832 4.88823C10.4336 4.84268 10.634 4.7668 10.7242 4.7162C10.8094 4.67068 10.8845 4.64537 10.8845 4.66056C10.8845 4.74656 10.6991 5.03999 10.5438 5.20187C10.3333 5.42955 10.3935 5.44978 10.8194 5.29799C11.075 5.21199 11.08 5.21199 11.0299 5.30811C10.9998 5.35871 10.8445 5.53578 10.6791 5.69767C10.3985 5.97593 10.3834 6.00629 10.3834 6.23901C10.3834 6.5982 10.2131 7.34695 10.0427 7.75674C9.72696 8.52572 9.05046 9.31999 8.37395 9.71966C7.42183 10.2812 6.15398 10.4229 5.08661 10.0941C4.73082 9.98274 4.11944 9.69943 4.11944 9.64886C4.11944 9.63367 4.30485 9.61343 4.53037 9.60838C5.00141 9.59826 5.47246 9.46671 5.87335 9.23399L6.14398 9.07211L5.83326 8.96587C5.39228 8.81408 4.9964 8.46501 4.89619 8.13617C4.8661 8.02993 4.87613 8.02486 5.15676 8.02486L5.44741 8.01981L5.20186 7.90346C4.9112 7.75674 4.64562 7.50883 4.51532 7.25587C4.4201 7.07375 4.29986 6.61337 4.33494 6.57796C4.34494 6.5628 4.45019 6.59313 4.57046 6.63361C4.91622 6.76008 4.96132 6.72972 4.76088 6.51725C4.38502 6.13277 4.26977 5.56107 4.45019 5.01975L4.53538 4.77692L4.8661 5.10575C5.54264 5.7685 6.33941 6.16313 7.25144 6.27949L7.50201 6.30984Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_771_467">
          <rect
            width="14.3284"
            height="14.3284"
            fill="white"
            transform="translate(0.537354 0.2388)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Telegram;
