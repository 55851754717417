import { children2 as childrenImg, desert, hussein as husseinImg } from "../../assets/images";
import contentClasses from "./ContentClasses.module.scss";
import classes from "./AboutContent.module.scss";
import Container from "../../ui/Container";

export const AboutContent = () => {
  return (
    <Container>
      <div className={`${classes.grid} ${contentClasses.container}`}>
        <article className={classes.text1}>
          <h2>Who we are</h2>
          <p>
            Seaside Help is the private charitable organization that is funded by the Seaside
            Holding Company. Seaside Help is dedicated to developing charitable initiatives around
            the globe in order to address the humanitarian, educational, economic, healthcare
            problems and to help people in need.
          </p>
        </article>
        <article className={classes.text2}>
          <h2>Mission & values</h2>
          <p>
            Seaside Help is aiming to support people who find themselves in tough life conditions
            and provide them an opportunity to better their life. There are millions of people who
            face the risk of hunger and thirst every day, there are millions of those who are in
            danger of health damage due to pollution of land and air, there are thousands of kids
            who would like to study but have no ability to. Most of the world reacts with ignorance
            or with some efforts that bring only short-term solutions that are unable to change the
            roots of the issue. And it is even harder to address these issues because most people
            today are divided in their mentality.{" "}
          </p>
        </article>
        <img className={classes.img1} src={desert} alt="desert" />
        <img className={classes.img2} src={childrenImg} alt="children" />
        <article className={classes.text3}>
          <h2>Together we thrive</h2>
          <div>
            <p>
              We envision the world as a united place with no boundaries and we strongly believe in
              the equality of opportunities for all. We aim to promote and portray this vision of
              the united world. Our efforts are put to systematically solve specific regional issues
              via establishment of self-sustainable systems that will be run by local communities.
              Each specific case requires a specific solution, therefore we will develop a specific
              plan of actions for each case for a specific budget.
            </p>
            <p>
              However, we want to outline that helping doesn’t always necessarily entail donating
              money! For start - just sharing a smile with a stranger will make his life a bit
              better. People get too focused on the dividing lines - their age, gender, income
              level, religion and whatever else. Helping is also about being able to ignore the
              dividing boundaries Seaside Help would like to promote this vision and lead by example
              to make the world a more fair place.
            </p>
          </div>
        </article>
        <article className={classes.text4}>
          <h2>Founder</h2>
          <h3>Hussein Karaki</h3>
          <p>
            I live by the principle of developing myself as a human being. I always work to be
            better than I was the day or the month or the year before. With Seaside Help I want to
            bring a difference to selfish and egocentric global culture. People around the globe are
            still experiencing famine, lack of water supply, lack of education, lack of shelters and
            infrastructure - even in the 2020s we are facing the same troubles as decades ago. At
            the same time modern culture is pushing people to get more separated than ever making it
            even more hard to unite and answer the challenges.
          </p>
          <p>
            By building long-lasting and self-sustainable socio-economic systems we aim to
            systematically address these issues via direct cooperation with local communities and
            without any external funding: all operations of Seaside Help are funded from the
            revenues generated by Seaside Club group of companies. Our team is formed of people with
            diverse backgrounds, different beliefs and religions. We are united to lead by example
            and promote the idea of unification and inclusivity - since only when we are united we
            can actually make a difference.
          </p>
        </article>
        <img className={classes.img3} src={husseinImg} alt="Hussein Karaki" />
      </div>
    </Container>
  );
};
