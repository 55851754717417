import React from "react";
import { HashLink } from "react-router-hash-link";
import SocialIcons from "../SocialIcons";
import { appLogoImage } from "../../assets/images";
import classes from "./NavigationMobile.module.scss";
import { navLinks } from "../../data/navigation";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

// TODO. Disable body scroll (scroll-lock)

const NavigationMobile: React.FC<Props> = ({ isOpen, onClose }) => {
  const classNames = isOpen
    ? `${classes["navigation-mobile"]} ${classes.show}`
    : classes["navigation-mobile"];

  return (
    <nav className={classNames}>
      <ul>
        {navLinks.map((item) => (
          <li onClick={onClose} key={item.id}>
            <HashLink to={item.url} scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}>
              {item.pageName}
            </HashLink>
          </li>
        ))}
      </ul>

      <div className={classes.description}>
        <div className={classes.logo}>
          <img src={appLogoImage} alt="Seaside Charity" />
          <h2>Seaside Help</h2>
        </div>
        <div className={classes.links}>
          <ul>
            <li> Tell us your issue</li>
            <li>support@gmail.com</li>
          </ul>
          <ul className={classes["right-aligned"]}>
            <li>
              <a
                style={{ textDecoration: "underline" }}
                href="http://seaside.club"
                target="_blank"
                rel="noreferrer"
                data-cursor="clickable">
                Seaside Club
              </a>
            </li>
            <li>
              <a
                style={{ textDecoration: "underline" }}
                href="http://crypto-hunters.tv"
                target="_blank"
                rel="noreferrer"
                data-cursor="clickable">
                Crypto Hunters
              </a>
            </li>
          </ul>
        </div>
      </div>
      <SocialIcons size={30} className={classes["social-icons"]} iconType="primary" />
    </nav>
  );
};

export default NavigationMobile;
