import React from "react";
import classes from "./Container.module.scss";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Container: React.FC<Props> = (props) => {
  return <div className={`${classes.container} ${props.className}`}>{props.children}</div>;
};

export default Container;
