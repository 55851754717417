import Circle from "./Circle";
import classes from "./Circles.module.scss";

const circles = [
  {
    amount: 768,
    unit: "MLN",
    title: "Undernourished People",
    text: "Nearly one in three people in the world (2.37 billion) did not have access to adequate food in 2020 year"
  },
  {
    amount: 771,
    unit: "MLN",
    title: "Don't have access to Pure water",
    text: "122 million people rely on surface water to meet their basic needs."
  },
  {
    amount: 2.4,
    unit: "MLN",
    title: "Newborn died in their first month",
    text: "Low- and middle-income countries continue to bear most of the burden of communicable diseases."
  },
  {
    amount: 370,
    unit: "MLN",
    title: "Children haven`t received school meals",
    text: "During school closures, which for some children have been the only reliable source of food and daily nutrition."
  },
  {
    amount: 11,
    unit: "MLN",
    title: "Premature deaths",
    text: "Pollution is the largest environmental cause of disease and premature death. Air pollution is a risk multiplier that exacerbates the health consequences of the pandemic."
  }
];

const Circles = () => {
  return (
    <div className={classes["circles-grid"]}>
      {circles.map((item, idx) => (
        <Circle
          key={idx}
          amount={item.amount}
          unit={item.unit}
          title={item.title}
          text={item.text}
          className={classes[`circle${idx + 1}`]}
        />
      ))}
    </div>
  );
};

export default Circles;
