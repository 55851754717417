import Container from "../../ui/Container";
import contentClasses from "./ContentClasses.module.scss";

export const ToSContent = () => {
  return (
    <Container>
      <div className={`${contentClasses.container}`}>
        <span>Date of Last Revision: May 12, 2022</span>
        <article>
          <p>
            Subject to these Terms of Service, as amended from time to time (“Terms of Service”),
            GoFundMe Inc. provides the GoFundMe platform to you through its website at
            www.gofundme.com and attendant mobile applications (collectively, with any new features
            and applications, the “Platform”) and the GoFundMe Community and related services
            (collectively, with the Platform, including any new features and applications, the
            “Services”). If you are an Organizer (as defined below), Beneficiary (as defined below)
            to a Fundraiser (as defined below), comment contributor, or Donor (as defined below)
            (collectively referred to herein as a “User”) located in the United States, you are
            contracting with GoFundMe Inc., 855 Jefferson Avenue, PO Box 1329, Redwood City, CA,
            94063. If you are a User located outside of the United States but not in either
            Australia or New Zealand, you are contracting with GoFundMe Ireland, Ltd., 70 Sir John
            Rogerson Quay, Dublin 2, Ireland. If you are a User located in Australia, you are
            contracting with GoFundMe Australia PTY Ltd., Tower One – International Towers Sydney,
            Level 46, 100 Barangaroo Avenue, 2000, Sydney, NSW. If you are a User located in New
            Zealand, you are contracting with GoFundMe New Zealand Limited, c/o Quigg Partners,
            Level 7, 36 Brandon Street, Wellington 6011. Unless specifically indicated otherwise,
            for purposes of the following Terms of Service, “GoFundMe,” “we,” “us,” “our,” and other
            similar terms, shall refer to the party with whom you are contracting.
          </p>
          <p>
            ARBITRATION, JURY TRIAL, AND CLASS ACTION WAIVER: EXCEPT AS OTHERWISE DESCRIBED IN THE
            DISPUTES SECTION BELOW, BY USING THE SERVICES , YOU AGREE THAT DISPUTES BETWEEN YOU AND
            US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO A
            JURY TRIAL OR TO PARTICIPATE IN A CLASS ACTION. PLEASE REVIEW THE DISPUTES SECTION
            CAREFULLY; BY USING THE SERVICES, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND
            UNDERSTAND ALL OF THESE TERMS OF SERVICE.
          </p>
          <p>
            We reserve the right, at our sole discretion, to change or modify portions of these
            Terms of Service at any time and without notice. When we do this, we will post the
            revised Terms of Service on this page and will indicate the date of such revision.
          </p>
          <p>
            Your continued use of the Services after the date of any such changes constitutes your
            acceptance of the new Terms of Service. To the extent allowed by law, the English
            version of these Terms of Service is binding and other translations are for convenience
            only. If you do not wish to accept the new Terms of Service, you may discontinue your
            use of the Services.
          </p>
        </article>
        <article>
          <h2>ACCESS AND USE OF THE SERVICES</h2>
          <p>
            The Services Description: The Services are offered as a platform to allow an individual,
            entity or non-profit organization (the “Organizer”) to post a fundraiser (“Fundraiser”)
            to the Platform to accept monetary donations (“Donations”) from donors (“Donors”) on
            behalf of the beneficiary of the Fundraiser (“Beneficiary”). Payment Processor: GoFundMe
            is not a payment processor and does not hold any funds. Instead, GoFundMe uses
            third-party payment processing partners to process Donations for a Fundraiser (“Payment
            Processor”). You acknowledge and agree that the use of Payment Processors is integral to
            the Services and that we exchange information with Payment Processors in order to
            facilitate the provision of the Services. See our Privacy Policy.
          </p>
          <p>
            Transaction Fee: Although there are no fees to start or maintain a Fundraiser, please
            keep in mind that a transaction fee, including credit and debit charges, is deducted
            from each donation (hereinafter and on the website referred to as “Transaction Fee”). To
            learn more about the Platform and the applicable Transaction Fee, visit GoFundMe
            Pricing.
          </p>
          <p>
            The Services are Platforms; We are not a Broker, Financial Institution, Creditor or
            Charity: The Services are administrative platforms only. GoFundMe facilitates the
            Fundraiser of the Organizers and permits Donors to make donations to these Fundraisers.
            GoFundMe is not a broker, agent, financial institution, creditor or 501(c)(3) nonprofit
            corporation.
          </p>
          <p>
            The Services Description: The Services are offered as a platform to allow an individual,
            entity or non-profit organization (the “Organizer”) to post a fundraiser (“Fundraiser”)
            to the Platform to accept monetary donations (“Donations”) from donors (“Donors”) on
            behalf of the beneficiary of the Fundraiser (“Beneficiary”). Payment Processor: GoFundMe
            is not a payment processor and does not hold any funds. Instead, GoFundMe uses
            third-party payment processing partners to process Donations for a Fundraiser (“Payment
            Processor”). You acknowledge and agree that the use of Payment Processors is integral to
            the Services and that we exchange information with Payment Processors in order to
            facilitate the provision of the Services. See our Privacy Policy.
          </p>
          <p>
            Transaction Fee: Although there are no fees to start or maintain a Fundraiser, please
            keep in mind that a transaction fee, including credit and debit charges, is deducted
            from each donation (hereinafter and on the website referred to as “Transaction Fee”). To
            learn more about the Platform and the applicable Transaction Fee, visit GoFundMe
            Pricing.
          </p>
          <p>
            The Services are Platforms; We are not a Broker, Financial Institution, Creditor or
            Charity: The Services are administrative platforms only. GoFundMe facilitates the
            Fundraiser of the Organizers and permits Donors to make donations to these Fundraisers.
            GoFundMe is not a broker, agent, financial institution, creditor or 501(c)(3) nonprofit
            corporation.
          </p>
        </article>
      </div>
    </Container>
  );
};
