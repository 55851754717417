import { useEffect } from "react";
import Header from "../../components/Header";
import Breadcrumbs from "../../components/Breadcrumbs";
import { ToSContent } from "../../components/Content";
import Footer from "../../components/Footer";
const TermsOfService = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    scrollToTop();
    window.addEventListener("load", scrollToTop);
    return () => {
      window.removeEventListener("load", scrollToTop);
    };
  }, []);
  return (
    <>
      <Header />
      <Breadcrumbs
        currentPage="Terms of Service"
        pageHeading="Charity Foundation Terms of Service"
      />
      <ToSContent />
      <Footer />
    </>
  );
};

export default TermsOfService;
