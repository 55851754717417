import React from "react";
import classes from "./IconStyles.module.scss";

type Props = {
  type: "primary" | "secondary";
  size?: number;
};

const Telegram: React.FC<Props> = ({ type, size }) => {
  let color: string;

  if (type === "secondary") {
    color = "#EB9309";
  } else {
    color = "#333333";
  }

  return (
    <svg
      className={classes[type]}
      width={size || 15}
      height={size || 15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_771_470)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.119385 7.40298C0.119385 11.3596 3.32691 14.5672 7.28356 14.5672C11.2402 14.5672 14.4477 11.3596 14.4477 7.40298C14.4477 3.44632 11.2402 0.2388 7.28356 0.2388C3.32691 0.2388 0.119385 3.44632 0.119385 7.40298ZM5.97013 10.6866L6.09201 8.86038L6.09192 8.86029L9.41398 5.86238C9.55977 5.73298 9.38216 5.66987 9.18861 5.78725L5.08873 8.37381L3.3178 7.8211C2.93535 7.70399 2.93261 7.44119 3.40365 7.25226L10.3045 4.59131C10.6197 4.4482 10.924 4.66701 10.8036 5.1494L9.6284 10.6875C9.54634 11.081 9.30855 11.1752 8.97908 10.9934L7.18885 9.67071L6.32834 10.5075C6.32562 10.5101 6.32294 10.5127 6.32025 10.5153C6.22401 10.609 6.14437 10.6866 5.97013 10.6866Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_771_470">
          <rect
            width="14.3284"
            height="14.3284"
            fill="white"
            transform="translate(0.119385 0.2388)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Telegram;
