import { useEffect } from "react";
import Header from "../../components/Header";
import Breadcrumbs from "../../components/Breadcrumbs";
import { PrivacyPolicyContent } from "../../components/Content/PrivacyPolicyContent";
import Footer from "../../components/Footer";

const PrivacyPolicy = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    scrollToTop();
    window.addEventListener("load", scrollToTop);
    return () => {
      window.removeEventListener("load", scrollToTop);
    };
  }, []);
  return (
    <>
      <Header />
      <Breadcrumbs
        currentPage="Privacy Policy"
        pageHeading="Privacy Policy for Charity Foundation "
      />
      <PrivacyPolicyContent />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
