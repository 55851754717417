/* eslint-disable prettier/prettier */
import Container from "../../ui/Container";
import { Link } from "react-router-dom";
import { appLogoImage } from "../../assets/images";
import { globeIcon, ScrollUp } from "../../assets/icons";
import classes from "./Footer.module.scss";
import SocialIcons from "../SocialIcons";

const Footer = () => {
  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <footer className={classes.footer}>
      <Container>
        <div className={classes.grid}>
          <div className={classes["logo-container"]}>
            <img src={appLogoImage} alt="LOGO" />
            <span>Seaside Help</span>
          </div>
          <div className={classes.list}>
            <h4>Site Map</h4>
            <ul>
              <li>
                <Link data-cursor="clickable" to="/about-us">
                  About Us
                </Link>
              </li>
              <li>
                <Link data-cursor="clickable" to="/terms-of-service">
                  Terms Of Service
                </Link>
              </li>

              <li>
                <Link data-cursor="clickable" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className={classes.list}>
            <h4>Connect with us</h4>
            <ul>
              <li> Tell us your issue</li>
              <li>info@seaside.club</li>
              <li> Our main site Seaside Club:</li>
              <li>
                <img src={globeIcon} alt="WWW" />
                <a
                  style={{ textDecoration: "underline" }}
                  href="http://seaside.club"
                  target="_blank"
                  rel="noreferrer"
                  data-cursor="clickable">
                  Seaside Club
                </a>
                <a
                  style={{ textDecoration: "underline" }}
                  href="http://crypto-hunters.tv"
                  target="_blank"
                  rel="noreferrer"
                  data-cursor="clickable">
                  Crypto Hunters
                </a>
              </li>
              <li>
                <SocialIcons
                  iconType="secondary"
                  size={25}
                  className={classes["social-icons-container"]}
                />
              </li>
            </ul>
          </div>
          <span data-cursor="clickable" className={classes["scroll-up"]}>
            <ScrollUp onClick={handleScroll} />
            <span data-cursor="clickable">Up</span>
          </span>
        </div>
        <small className={classes.copyright}>All rights reserved for Seaside Help 2022.</small>
      </Container>
    </footer>
  );
};

export default Footer;
