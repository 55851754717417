import React from "react";
import Container from "../../ui/Container";
import { appLogoImage } from "../../assets/images";

import classes from "./HeroSection.module.scss";

type Props = {
  children: React.ReactNode;
};

const HeroSection: React.FC<Props> = (props) => {
  return (
    <section className={classes.container}>
      <div className={classes.background}>
        <div className={classes["white-gradient"]}>
          {props.children}

          <Container className={classes["hero-content"]}>
            <img src={appLogoImage} alt="Logo" className={classes.logo} />
            <div className={classes.text}>
              <h1>
                <span>Seaside</span>
                <span>Help</span>
              </h1>
              <p className={classes.subheading}>
                Private charitable organization funded by Seaside Club
              </p>
              <h2>OUR MISSION</h2>
              <p>
                Seaside Help is aiming to support people who find themselves in tough life
                conditions and provide them an opportunity to better their life. There are millions
                of people who face the risk of hunger and thirst every day, there are millions of
                those who are in danger of health damage due to pollution of land and air, there are
                thousands of kids who would like to study but have no ability to.
              </p>
              <p>
                Most of the world reacts with ignorance or with some efforts that bring only
                short-term solutions that are unable to change the roots of the issue. And it is
                even harder to address these issues because most people today are divided in their
                mentality.
              </p>
            </div>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
